import React, { ComponentProps, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import LazyHydrate from 'react-lazy-hydration'
import { isSsr } from '../../utils/isSsr'

export type LazyComponentProps = Omit<ComponentProps<typeof LazyHydrate>, 'whenVisible' | 'whenIdle' | 'didHydrate'> & {
  performanceMarkName?: string
}

export const LazyComponent: React.FC<PropsWithChildren<LazyComponentProps>> = ({ children, performanceMarkName, ...props }) => {
  const mqlRef = useRef(isSsr() ? undefined : window.matchMedia('(min-width: 1024px)'))
  const [isDesktop, setIsDesktop] = useState(mqlRef.current?.matches ?? false)

  const onHydrate = useCallback(() => {
    window.performance.mark(performanceMarkName ? `Hydrate Lazy Component: ${performanceMarkName}` : 'Hydrate Lazy Component', {
      detail: {
        name: performanceMarkName,
        props,
      },
    })
  }, [performanceMarkName])

  useEffect(() => {
    if (mqlRef.current) {
      mqlRef.current.addEventListener('change', (e) => {
        setIsDesktop(e.matches)
      })
    }
  }, [mqlRef])

  return (
    <LazyHydrate didHydrate={onHydrate} whenVisible={true} whenIdle={isDesktop} { ...props }>
      {children}
    </LazyHydrate>
  )
}
