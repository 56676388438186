import {
  AnimatePresence,
  motion,
  TargetAndTransition,
  Transition,
  useAnimation,
  Variant,
  VariantLabels,
} from 'framer-motion'
import React, { PropsWithChildren, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export type Animation = {
  visible?: Variant | Readonly<Variant>
  hidden?: Variant | Readonly<Variant>
}

type BounceScaleLessType = {
  visible: {
    scale: readonly number[]
    transition: {
      duration: number
      times: readonly number[]
    }
  }
  hidden: {
    scale: number
  }
}

type BounceScaleLessVideoType = {
  visible: {
    scale: readonly number[]
    transition: {
      delay: number
      duration: number
      times: readonly number[]
    }
  }
  hidden: {
    scale: number
  }
}

type BounceScaleLessFastType = {
  visible: {
    scale: readonly number[]
    transition: {
      duration: number
    }
    opacity: number
  }
  hidden: {
    scale: number
    opacity: number
  }
}

type DefaultType = {
  visible: {
    scale: number
    opacity: number
  }
  hidden: {
    scale: number
    opacity: number
  }
}

type EasyFadeOutType = {
  visible: {
    scale: number
    opacity: number
  }
  hidden: {
    scale: number
    opacity: number
  }
}

type EasySlidingDownFadeOutType = {
  visible: {
    scale: number
    y: number
    opacity: number
  }
  hidden: {
    scale: number
    y: number
    opacity: number
  }
}

type EasySlidingUpFadeOutType = {
  visible: {
    scale: number
    y: number
    opacity: number
  }
  hidden: {
    scale: number
    y: number
    opacity: number
  }
}

type EasyFadeInType = {
  visible: {
    scale: number
    opacity: number
  }
  hidden: {
    scale: number
    opacity: number
  }
}

type BounceScaleType = {
  visible: {
    scale: readonly number[]
    transition: {
      duration: number
      times: readonly number[]
    }
  }
  hidden: {
    scale: number
  }
}

type ChildByChildType = {
  visible: {
    x: number
    scale: number
    opacity: number
  }
  hidden: {
    x: number
    scale: number
    opacity: number
  }
}
type EasyFadeInStillType = {
  visible: {
    x: number
    scale: number
    opacity: number
  }
  hidden: {
    x: number
    scale: number
    opacity: number
  }
}

type EasySlidingLeftType = {
  visible: {
    x: number
    transition: {
      times: readonly number[]
      duration: number
    }
  }
  hidden: {
    x: number
  }
}

type EasySlidingTopFadeIn = {
    visible: {
    y:  number
    opacity: number
  }
  hidden: {
    y: number
    opacity: number
  }
}

type EasySlideLeftType = {
  visible: {
    opacity: number
    x: number
  }
  hidden: {
    opacity: number
    x: number
  }
}

type EasySlideRightType = {
  initial: {
    x: number
  }
  visible: {
    opacity: number
    x: number
    left: number
  }
  hidden: {
    opacity: number
    x: number
  }
}

type EasySlideRightFadeIn = {
  hidden: {
    x: number
    opacity:number
  }
  visible: {
    x: number
    opacity:number
  }
}

type EasySlideBottomToTopFadeInType = {
  hidden : {
    opacity: number
    y: number
  }
  visible: {
    opacity: number
    y: number
  }
}

type EasyFadeInTwoColStaggeredType = {
  visible: {
    x: number
    y: number
    opacity: number
  }
  hidden: {
    x: number
    y: number
    opacity: number
  }
}

type FadeAnimationsProps = {
  bounceScaleLess?: BounceScaleLessType
  bounceScaleLessVideo?: BounceScaleLessVideoType
  bounceScaleLessFast?:BounceScaleLessFastType
  default?: DefaultType
  easyFadeOut?: EasyFadeOutType
  easySlidingDownFadeOut?: EasySlidingDownFadeOutType
  easySlidingUpFadeOut?: EasySlidingUpFadeOutType
  easyFadeIn?: EasyFadeInType
  easyFadeInStill?: EasyFadeInStillType
  bounceScale?: BounceScaleType
  childByChild?: ChildByChildType
  easySlidingLeft?: EasySlidingLeftType
  easySlideLeft?: EasySlideLeftType
  easySlideRight?: EasySlideRightType
  easySlidingTopFadeIn?: EasySlidingTopFadeIn
  easySlideRightFadeIn?: EasySlideRightFadeIn
  easySliderBottomToTopFadeIn ?: EasySlideBottomToTopFadeInType
  easyFadeInTwoColStaggered ?: EasyFadeInTwoColStaggeredType
}

export const FadeAnimations: FadeAnimationsProps = {
  'default': {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  },
  'easyFadeIn': {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0.75 },
  },
  'easyFadeInStill': {
    visible: { opacity: 1, scale: 1, x: 0 },
    hidden: { opacity: 0, scale: 1, x: 0 },
  },
  'easyFadeOut': {
    visible: { opacity: 1, scale: -1 },
    hidden: { opacity: 0, scale: -0.75 },
  },
  'childByChild': {
    visible: { opacity: 1, scale: 1, x: 10 },
    hidden: { opacity: 0, scale: 1, x: 0 },
  },
  'easySliderBottomToTopFadeIn': {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  },
  'easySlidingDownFadeOut': {
    visible: { opacity: 1, scale: 1, y: -20 },
    hidden: { opacity: 0, scale: 1, y: 20 },
  },
  'easySlidingTopFadeIn': {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 40 },
  },
  'bounceScale': {
    visible: {
      scale: [0, 0.4, 0.8, 1.2, 1],
      transition: {
        times: [0, 0.2, 0.4, 0.6, 0.8, 1],
        duration: 1,
      },
    },
    hidden: { scale: 0 },
  },
  'bounceScaleLess': {
    visible: {
      scale: [0, 0.4, 0.8, 1.05, 1],
      transition: {
        times: [0, 0.2, 0.4, 0.6, 0.8, 1],
        duration: 1,
      },
    },
    hidden: { scale: 0 },
  },
  'bounceScaleLessVideo': {
    visible: {
      scale: [0, 1.05, 1],
      transition: {
        delay: 0.5,
        times: [0, 0.8, 1],
        duration: 1,
      },
    },
    hidden: { scale: 0 },
  },
  'bounceScaleLessFast': {
    visible: {
      scale: [0.9, 1],
      transition: {
        duration: .6,
      },
      opacity: 1,
    },
    hidden: { scale: .9, opacity: 0 },
  },
  'easySlidingLeft': {
    visible: {
      x: 0,
      transition: {
        times: [0, 0.2, 0.4, 0.6, 0.8, 1],
        duration: 1,
      },
    },
    hidden: {
      x: -200,
    },
  },
  'easySlideLeft': {
    visible: {
      opacity: 1,
      x: 0,
    },
    hidden: {
      opacity: 0,
      x: -20,
    },
  },
  'easySlideRight': {
    initial: {
      x: -1000,
    },
    visible: {
      opacity: 1,
      x: -3,
      left: 0,
    },
    hidden: {
      opacity: 0,
      x: -20,
    },
  },
  'easySlideRightFadeIn': {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -40 },
  },
  'easyFadeInTwoColStaggered': {
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
    },
    hidden: {
      opacity: 0,
      x: 0,
      y: 20,
    },
  },
} as const

export interface IFadeInWhenVisibleProps {
  animation?: keyof typeof FadeAnimations | Animation
  transition?: Transition
  everyVisible?: boolean
  exitAnimation?: TargetAndTransition | VariantLabels
  reRenderKey?: string | number
  isImg?: boolean
  srcImg?: string
  delay?: number
  className?: string
  waitBeforeImageIsLoaded?: boolean
}

export const FadeInWhenVisible: React.FC<PropsWithChildren<IFadeInWhenVisibleProps>> = (props) => {
  const {
    children,
    everyVisible,
    exitAnimation,
    reRenderKey,
    isImg,
    srcImg,
    delay,
    className,
  } = props

  let animation = props.animation || undefined
  const transition = props.transition || { duration: 0.3, delay: delay }

  if (typeof animation === 'string') {
    animation = FadeAnimations[animation]
  }

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }

    if (!inView && everyVisible) {
      controls.start('hidden')
    }
  })

  return (
    <>
      {
        isImg
          ?
          <motion.img
            ref={ref}
            key={reRenderKey}
            src={srcImg}
            transition={transition}
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 20 }}
            exit={{ opacity: 0, y: -20 }}
            alt="img"
          />
          :
          <AnimatePresence exitBeforeEnter>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              exit={exitAnimation}
              transition={transition}
              variants={animation}
              key={reRenderKey}
              className={className && className}
            >
              {children}
            </motion.div>
          </AnimatePresence>
      }
    </>
  )
}
