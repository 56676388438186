import dynamic from 'next/dynamic'
import React, { PropsWithChildren } from 'react'
import { LazyComponent } from '../../components/LazyComponent'
import { FooterProps } from './index'

const DynamicFooter = dynamic(() => import('./index'))

export const LazyFooter: React.FC<PropsWithChildren<FooterProps>> = (props) => (
  <LazyComponent performanceMarkName={'footer'}>
    <DynamicFooter { ...props } />
  </LazyComponent>
)
