import Head from 'next/head'
import React, { PropsWithChildren } from 'react'
import type { Thing, WithContext } from 'schema-dts'

export type StructuredDataProps<T extends Thing> =  {
  schema: WithContext<T>
}

export const StructuredData: React.FC<PropsWithChildren<StructuredDataProps<Thing>>> = ({ schema })=> {
  return (
    <Head>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Head>
  )
}

