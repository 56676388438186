import classnames from 'classnames'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { ISbRichtext } from 'storyblok-js-client'
import { Button } from '../../components/Button'
import { Column } from '../../components/Column'
import {
  BlockquoteType,
  BlokType,
  BulletListType,
  HeadingType,
  HorizontalRuleType,
  OrderedListType,
  ParagraphType,
} from '../../components/Column/blokTypes'
import { FlexibleContent } from '../../components/FlexibleContent'
import { Grid } from '../../components/Grid'
import { UnOrderedList } from '../../components/UnOrderedList'
import { RenderListItems } from '../../components/UnOrderedList/RenderListItems'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import classes from './TwoColCollapsible.module.scss'

type CustomListType = {
  title: string
  is_custom_title: boolean
  list: {
    component: 'fcp_custom_list'
    color: string
    content: {
      iconCode: string
      rotateIcon: string
      colour: string
      blob: {
        plugin: 'list-blob'
        blob: boolean
        rotate: 0 | 90 | 180 | 270
      }
      isLink: boolean
      itemLink: {
        cached_url: string
        url: string
      }
      text: ISbRichtext
    }[]
  }[]
}

export type TwoColCollapsableProps = {
  left_col_desktop: {
    title: string
    content: {
      content: (HeadingType|ParagraphType|HorizontalRuleType|BulletListType|OrderedListType|BlockquoteType|BlokType)[]
    }
  }[]
  left_col_mobile: {
    title: string
    content: {
      content: (HeadingType|ParagraphType|HorizontalRuleType|BulletListType|OrderedListType|BlockquoteType|BlokType)[]
    }
  }[]
  left_col_title?: string
  right_col_paragraph?: CustomListType[]
  isCollapsed?: boolean
}

const renderListItems = (listData: CustomListType, index: number) => {
  const itemsClasses = [classes.servicesTitle]
  const isMobile = useMediaQuery('(max-width: 1023px)')

  const unOrderedListItems = listData?.list?.[0]?.content?.map((item, i: number) => {
    return {
      iconCode: item?.iconCode,
      rotateIcon: item?.rotateIcon,
      text: item?.text,
      color: item?.colour,
      link: item?.itemLink?.cached_url !== '',
      linkHref: item?.itemLink?.cached_url,
      linkTarget: false,
      key: i,
      blob: item?.blob,
    }
  })

  if (!listData.is_custom_title) itemsClasses.push(classes.optional)

  return (
    <>
      {!isMobile ? (
        <motion.div className={classes.servicesListWrapper} key={index} initial={{ opacity: 0 }} animate={{
          opacity: 1,
          transition: {
            duration: 1,
          },
        }}>
          <h3 className={classnames(itemsClasses)}>
            {listData.title}
          </h3>
          <UnOrderedList>
            <RenderListItems
              items={unOrderedListItems}
              color={listData?.list?.[0]?.color ? listData?.list?.[0]?.color : undefined}
            />
          </UnOrderedList>
        </motion.div>
      ) : (
        <div className={classes.servicesListWrapper} key={index}>
          <h3 className={classnames(itemsClasses)}>
            {listData.title}
          </h3>
          <UnOrderedList>
            <RenderListItems
              items={unOrderedListItems}
              color={listData?.list?.[0]?.color ? listData?.list?.[0]?.color : undefined}
            />
          </UnOrderedList>
        </div>
      )}
    </>
  )
}

export const TwoColCollapsable: React.FC<{ slice: TwoColCollapsableProps }> = ({ slice }) => {
  const mobileBreakPoint = useMediaQuery('(max-width: 1023px)')
  const columnsClasses = [classes['two-col-collapsable__columns']]
  const is4K = useMediaQuery('(min-width: 2000px)')
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const [collapsed, setCollapsed] = useState(slice.isCollapsed)

  if (!collapsed) columnsClasses.push(classes['two-col-collapsable__columns--revealed'])

  const toggleCollapse = () => {
    return slice.isCollapsed ? (collapsed ? setCollapsed(false) : setCollapsed(true)) : setCollapsed(false)
  }

  const leftColumnDesktopContent = slice.left_col_desktop[0]
  const leftColumnMobileContent = slice.left_col_mobile[0] 

  const renderLeftColumnContent = (leftColumnContent: {title: string; content: Omit<ISbRichtext, 'type'>})=> {
    return (
      <Grid item className={classes['two-col-collapsable__columns-left']} xxs={12} md={6}>
        {leftColumnContent.title ?
          <h2>{leftColumnContent.title}</h2> : null}
        {leftColumnContent.content ?
          <Column className={classes.column} body={{ content: leftColumnDesktopContent?.content?.content }} href={''} variant={'default'} src={{
            filename: '',
            alt: '',
          }} component={'column'} _uid={''} size={'default'} align={'center'} full_width={true} mob_size={'default'} />: null}
      </Grid>
    )
  }

  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: is4K ? 6 : 1,
        duration: .5,
        staggerChildren: 1,
      },
    },
    hidden: {
      opacity: 1,
      y: 0,
    },
  }

  const TwoColComponent = () => {
    return (
      <div
        className={classes.twoColCollapsableContainer}
      >
        <FlexibleContent row>
          <Grid row
            className={classnames(columnsClasses)}
          >
            {
              mobileBreakPoint && leftColumnMobileContent ? renderLeftColumnContent(leftColumnMobileContent) : ( !mobileBreakPoint && leftColumnDesktopContent ? renderLeftColumnContent(leftColumnDesktopContent) : null)
            }
            {!mobileBreakPoint && (
              <Grid item className={classes['two-col-collapsable__columns-right']} xxs={12} md={6}>
                {slice.right_col_paragraph ?
                  <motion.section
                    variants={variants}
                    initial="hidden"
                    animate="visible"
                  >
                    {
                      slice.right_col_paragraph.map((listData, index) =>
                      {
                        return (
                          renderListItems(listData, index)
                        )
                      })
                    }
                  </motion.section> : null}
              </Grid>
            )}
          </Grid>
          <Grid row className={classes.showMoreWrapper}>
            <Grid item className={classes['two-col-collapsable__show-more']}>
              {collapsed && <Button
                icon={collapsed ? 'plus' : 'minus'}
                variant={'primary'}
                onClick={() => toggleCollapse()}
              >
                {collapsed ? 'Read more' : 'Read less'}
              </Button>}
            </Grid>
          </Grid>

          {mobileBreakPoint && (
            <Grid row className={classes.columnRightWrapper}>
              <Grid item className={classes['two-col-collapsable__columns-right']} xs={12}>
                {slice.right_col_paragraph ?
                  <section className={classes.mobileServicesLists}>
                    { slice?.right_col_paragraph?.[0] &&
                      renderListItems(slice.right_col_paragraph[0], 0)
                    }
                  </section> : null}
              </Grid>
            </Grid>
          )}
        </FlexibleContent>
      </div>
    )
  }

  return (
    <>
      {!isMobile ? (
        <motion.div
          initial={{ opacity: 0, x: 0, y: 20 }}
          animate={{ opacity: 1, x: 0, y: 0 }}
          transition={{ duration: 0.5, delay: 3 }}
          className={classes.twoColCollapsableWrapper}
        >
          <TwoColComponent />
        </motion.div>
      ) : (
        <div className={classes.twoColCollapsableWrapper}>
          <TwoColComponent />
        </div>
      )}
    </>
  )
}

export default TwoColCollapsable
