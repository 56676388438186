/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetStaticProps } from 'next'
import dynamic from 'next/dynamic'
import { withRouter } from 'next/router'
import React, { ComponentType } from 'react'
import { DefaultLayout } from '../components/layouts/DefaultLayout'
import { SettingsContext } from '../contexts/settingsContext'
import { LazyFooter } from '../slices/Footer/LazyFooter'
import { HomeBannerAndVideo } from '../slices/Home/HomeBanerAndVideo/HomeBannerAndVideo'
import SeoCard from '../slices/SeoCard'
import TwoColCollapsable from '../slices/TwoColCollapsable'
import StoryblokService from '../utils/storyblokService'

// https://vercel.com/docs/infrastructure/data-cache/manage-data-cache#disabling-vercel-data-cache
export const fetchCache = 'force-no-store'

type ComponentRegistry = {
  [key: string]: ComponentType<any>
}

const Components: ComponentRegistry = {
  basic_article:                dynamic(() => import('../slices/BasicArticle')),
  two_col_collapsable:          TwoColCollapsable,
  home_featured_posts:          dynamic(() => import('../slices/FeaturedPosts')),
  flexible_content:             dynamic(() => import('../slices/FlexibleContent')),
  team_card:                    dynamic(() => import('../slices/TeamCard')),
  fcp_gallery:                  dynamic(() => import('../components/SectionGallery')),
  fcp_downloads:                dynamic(() => import('../slices/DownloadList')),
  home_banner_and_video:        HomeBannerAndVideo,
  home_featured_projects:       dynamic(() => import('../components/FeaturedCarousel')),
  home_services_tab_component:  dynamic(() => import('../slices/Home/HomeTabbedCarousel/HomeTbbedCarousel')),
  introduction:                 dynamic(() => import('../slices/Introduction')),
  fcp_related_content:          dynamic(() => import('../slices/RelatedContent')),
}

type Props = {
  doc: any
  settings: any
  menus: Record<string, unknown>
  footers: Record<string, unknown>
  router: any
}

class Index extends React.Component<Props> {
  state: {
    locale: string
    path: string
    story : any
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      locale: props.router.locale,
      path: props.router.asPath,
      story: props.doc.data.story,
    }
  }

  componentDidMount() {
    StoryblokService.initEditor(this as any)
  }

  static getDerivedStateFromProps(props: Props, state: any) {
    const propsStory = props?.doc?.data?.story

    if (props.router.locale !== state.locale || props.router.asPath !== state.path) {
      return {
        locale: props.router.locale,
        path: props.router.asPath,
        story: propsStory,
      }
    }

    return null
  }

  render() {
    // Top-level hook for the visual editor
    const content = this.state.story.content
    const siteTitle = this.props.settings?.site_name ?? 'undefined'
    const { head, body, static_footer, footer_cta } = content

    return (
      <DefaultLayout title={siteTitle}>
        <SettingsContext.Provider value={this.props.settings}>
          {head && head.length > 0 && (
            <SeoCard slice={head[0]} />
          )}
          {body.slice(0)?.map((slice: any) => {
            const Component = Components[slice?.component]

            return Component ? (
              <Component
                slice={slice}
                key={slice._uid}
                settings={this.props.settings}
                footers={this.props.footers}
                menu={this.props.menus} />
            ) : null
          })}
          <LazyFooter cta={footer_cta} static_footer={static_footer} />
        </SettingsContext.Provider>
      </DefaultLayout>
    )
  }
}

export const getStaticProps: GetStaticProps = async () => {
  const settingsDoc   = await StoryblokService.getDocumentBySlug('global-content-types/settings')
  const menuDocs      = await StoryblokService.getCollection('menus')
  const footerDocs    = await StoryblokService.getCollection('footers')
  const doc           = await StoryblokService.getDocumentBySlug('home', {
    resolve_relations: 'featured_posts.posts,fcp_related_content.posts,team_card.image_source',
    resolve_links: 'story',
  })

  return {
    props: {
      doc,
      footers: footerDocs,
      menus: menuDocs,
      settings: {
        ...settingsDoc?.data?.story?.content,
      },
    },
    revalidate: 300,
  }
}

export default withRouter(Index as any)
