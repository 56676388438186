/* eslint-disable @typescript-eslint/no-explicit-any */
export const isEmpty = (value: any) => {
  if (value === null || value === undefined) {
    return true
  }

  if (typeof value === 'string') {
    return value.trim().length === 0
  }

  return false
}